import * as React from "react";
import {createTheme} from "@mui/material/styles";

export default function OneBusinessTheme() {

    const theme = createTheme({
        palette: {
            primary: {
                main: "#1e387a",
            },
            secondary: {
                main: "#f3f3f3",
            },
            text: {
                primary: "#133D3D",
            }
        },
        typography: {
            fontFamily: 'Open Sans',
            OneBusinessHeader: {
                margin: 0,
                fontSize: "1.2rem",
                lineHeight: 1.57,
                display: "block",
                color: "white"
            },
            companyDetailsHeader: {
                WebkitTextStroke: "0.7px",
                margin: 0,
                fontSize: "0.8rem",
                lineHeight: 1.57,
                display: "block"
            },
            companyDetailsBody: {
                margin: 0,
                fontSize: "0.8rem",
                lineHeight: 1.57,
                display: "block"
            },
            productPriceDisplay: {
                WebkitTextStroke: "0.4px",
                margin: 0,
                fontSize: "1rem",
                lineHeight: 1.57,
                display: "block"
            },
            productVatDisplay: {
                margin: 0,
                fontSize: "0.9rem",
                display: "block"
            },
            companyMailSearchHeader: {
                margin: 0,
                fontSize: "1.3rem",
                lineHeight: 1.57,
                fontWeight:900
            },
            companyMailSearchSubHeader: {
                margin: 0,
                fontSize: "0.8rem",
                lineHeight: 1.57,
                fontWeight: 500
            },
            companyMailSearchHeavyFieldDescriptor: {
                margin: 0,
                fontSize: "0.9rem",
                fontWeight: 900,
                lineHeight: 1.57,
            },
            companyMailSearchHeavyFieldValue: {
                margin: 0,
                fontSize: "0.9rem",
                fontWeight: 500,
                lineHeight: 1.57,
            },
            companyMailSearchFieldDescriptor: {
                margin: 0,
                fontSize: "0.8rem",
                fontWeight: "strong",
                lineHeight: 1.57,
            },
            companyMailSearchFieldValue: {
                margin: 0,
                fontSize: "0.8rem",
                lineHeight: 1.57,
            },
            basketSubHeader: {
                WebkitTextStroke: "0.5px",
                margin: 0,
                fontSize: "1.2rem",
                lineHeight: 1.57,
                display: "block"
            },
            basketItem: {
                WebkitTextStroke: "0.4px",
                margin: 0,
                fontSize: "0.9rem",
                lineHeight: 1.57,
                display: "block"
            },
            formHeader: {
                fontWeight: 900,
                color: "#133D3D",
                fontSize: "1.5rem"
            },
            h5: {
                fontWeight: "950",
                color: "#133D3D"
            },
            h6: {
                fontSize: "1rem",
                WebkitTextStroke: "0.7px",
                color: "#133D3D"
            },
            companyQuickSearchHeader:{
                fontSize: "1rem",
                fontWeight:"900",
                wordWrap: 'break-word'
            },
            companyQuickSearchBodyHeader:{
                fontSize: "0.7rem",
                fontWeight:"900",
                wordWrap: 'break-word'
            },
            companyQuickSearchBodyText:{
                fontSize: "0.7rem",
                fontWeight:"500",
                wordWrap: 'break-word'
            },
            companyQuickSearchSubscriptionStatus:{
                fontSize: "0.7rem",
                fontWeight:"950",
                wordWrap: 'break-word'
            },
            companyQuickSearchSummaryLink:{
                color:"#082874",
                fontSize: "0.7rem",
                fontWeight:"700",
                cursor:"pointer",
                wordWrap: 'break-word',
                textDecoration: 'underline'
            },
            forgotPasswordLink:{
                color:"#133D3D",
                fontSize: "0.85rem",
                fontWeight:"950",
                cursor:"pointer"
            },
            loginInfoMessages:{
                color:"#133D3D",
                fontSize: "0.85rem",
                fontWeight:"950"
            }
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: "#f3f3f3",
                        color: "#1e387a",
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#1e387a",
                        color: "white",
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        WebkitTextStroke: "0.75px",
                        color: "#133D3A"
                    },
                }
            },
            MuiInput: {
                styleOverrides: {
                    input: {
                        "&::placeholder": {
                            color: "green"
                        },
                        color: "green", // if you also want to change the color of the input, this is the prop you'd use
                    }
                }
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        fontWeight: "800",
                        color: "#133D3A"
                    },
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: "950",
                        color: "#133D3D",
                        textTransform: "none"
                    }
                }
            }
        }
    });

    theme.menuLogoWidth= 150;
    theme.menuMiniLogoWidth= 30;
    theme.inputLabelProps = { color: "grey", fontWeight: 600, fontSize: "0.9rem" };
    theme.logoUrl = "/tenantLogos/oneBusiness@3x.png";
    theme.loginBackgroundImage = "/tenantLogos/oneBusinessLoginBackground.png";
    theme.loginLogo = "tenantLogos/oneBusinessLoginLogo.png";
    theme.miniLogoUrl = "/tenantLogos/oneBusiness@3xMini.png";
    theme.faviconUrl =  "/tenantLogos/oneBusiness@3xMini.png";
    theme.documentTitle = "OneBusiness";
    theme.termsAndConditionsLink = "";
    theme.privacyPolicyLink = ""
    theme.menuFooterText = "© One Business Apps Limited";
    
    return theme;
}