import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import {Box, Checkbox, FormControlLabel, Grid, MenuItem} from "@mui/material";
import {FormGroup} from "reactstrap";
import {useTheme} from "@mui/material/styles";

export default function UpdateUserForm({formState, control, watch, defaultValues, adminRole, standardRole, user, readonly}) {
    const theme = useTheme();
    const watchEmail = watch("email", defaultValues.email);

    const dateTimeFormatter = (value) => {
        const date = new Date(value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleString('en-GB');
    };
    
     return (
        <Box sx={{ maxWidth: 1200 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="title"
                        control={control}
                        defaultValue={defaultValues.title}
                        render={({field}) => (
                            <TextField
                                {...field}
                                select
                                label="Title"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={readonly}
                            >
                                <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                <MenuItem key="1" value={1}>Mr</MenuItem>
                                <MenuItem key="2" value={2}>Mrs</MenuItem>
                                <MenuItem key="3" value={3}>Ms</MenuItem>
                                <MenuItem key="4" value={4}>Miss</MenuItem>
                                <MenuItem key="5" value={5}>Dr</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="role"
                        control={control}
                        defaultValue={defaultValues.role}
                        rules={{required: 'Role is required'}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                select
                                label="Role"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={readonly || user.userId == defaultValues.id}
                                error={!!formState.errors.role}
                                helperText={formState.errors.role ? formState.errors.role.message : ''}
                            >
                                <MenuItem key="1" value={adminRole}>Admin</MenuItem>
                                <MenuItem key="2" value={standardRole}>Standard</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue={defaultValues.firstName}
                        rules={{required: 'First Name is required'}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="First Name *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={readonly}
                                error={!!formState.errors.firstName}
                                helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <Controller
                        name="status"
                        control={control}
                        defaultValue={defaultValues.status}
                        rules={{required: 'Status is required'}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                select
                                label="Status"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={readonly || user.userId == defaultValues.id}
                                error={!!formState.errors.status}
                                helperText={formState.errors.status ? formState.errors.status.message : ''}
                            >
                                <MenuItem key="1" value={1}>Enabled</MenuItem>
                                <MenuItem key="2" value={2}>Disabled</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue={defaultValues.lastName}
                        rules={{required: 'Last Name is required'}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Last Name *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={readonly}
                                error={!!formState.errors.lastName}
                                helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <Controller
                        name="createdAt"
                        control={control}
                        defaultValue={dateTimeFormatter(defaultValues.createdAt)}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Date Created"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue={defaultValues.phoneNumber}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Phone"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <Controller
                        name="activatedAt"
                        control={control}
                        defaultValue={dateTimeFormatter(defaultValues.activatedAt)}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Activation Date"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue={defaultValues.email}
                        rules={{
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Email *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={readonly}
                                error={!!formState.errors.email}
                                helperText={formState.errors.email ? formState.errors.email.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <Controller
                        name="lastLogin"
                        control={control}
                        defaultValue={dateTimeFormatter(defaultValues.lastLogin)}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Last Login"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="repeatEmail"
                        control={control}
                        defaultValue={defaultValues.email}
                        rules={{
                            required: 'Repeat Email is required',
                            validate: {
                                emailEqual: value => (value === watchEmail) || "Email's are not identical",
                            },
                        }}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Repeat Email *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={readonly}
                                error={!!formState.errors.repeatEmail}
                                helperText={formState.errors.repeatEmail ? formState.errors.repeatEmail.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                </Grid>
                <Grid item xs={24} sm={16} md={12}>
                    <Controller
                        name="marketingOptOut"
                        control={control}
                        defaultValue={defaultValues.marketingOptOut}
                        render={({ field }) => (
                            <FormGroup>
                                <FormControlLabel
                                    disabled={readonly}
                                    control={<Checkbox {...field} checked={field.value} />}
                                    label="Tick this box if you would like to opt out of marketing communications from our closely selected partners."
                                />
                            </FormGroup>
                        )}
                    />
                </Grid>
            </Grid>
        </Box>


    );

}